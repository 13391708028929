import memoize from 'lodash/memoize'
/**
 * Truncate a transaction or address hash
 */
const truncateHash = memoize(
  (address: string, startLength = 0, endLength = 5) => {
    if (!address) return ''
    if (startLength === 0) return `${address.substring(address.length - endLength)}`

    return `${address.substring(0, startLength)}...${address.substring(address.length - endLength)}`
  },
  (address, startLength, endLength) => `${address}#${startLength}#${endLength}`,
)

export default truncateHash
