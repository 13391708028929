import { ChainId } from '@pancakeswap/chains'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import BigNumber from 'bignumber.js'
import { pancakeRouter02ABI } from 'config/abi/IPancakeRouter02'
import { useMemo } from 'react'
import { Address, useAccount, useContractRead } from 'wagmi'
import { useActiveChainId } from './useActiveChainId'

const usePlsPriceUSD = (routerAddress: Address) => {
  return usePlsPriceUSDByChain(routerAddress)
}

export const usePlsPriceUSDByChain = (routerAddress: Address, chainIdOverride?: ChainId) => {
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()

  const { data, status, ...rest } = useContractRead({
    chainId: chainIdOverride || chainId,
    abi: pancakeRouter02ABI,
    address: routerAddress,
    functionName: 'getAmountsOut',
    args: [
      BigInt('1000000000000000000'),
      [
        '0xA1077a294dDE1B09bB078844df40758a5D0f9a27', // wpls
        '0xefD766cCb38EaF1dfd701853BFCe31359239F305', // edai
      ],
    ],
    // enabled: !!account,
    watch: true,
  })

  return {
    ...rest,
    fetchStatus: status,
    price: useMemo(
      () => (typeof data !== 'undefined' ? new BigNumber(data[data.length - 1].toString()) : BIG_ZERO),
      [data],
    ),
  }
}

export default usePlsPriceUSD
