import React from "react";
import { styled } from "styled-components";
import { Colors } from "../../theme";
import { Flex } from "../Box";
import Skeleton from "../Skeleton/Skeleton";
import Text from "../Text/Text";

export interface Props {
  color?: keyof Colors;
  cakePriceUsd?: number;
  showSkeleton?: boolean;
  chainId: number;
}

const PriceLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.3s;
  }
  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
`;

const CakePrice: React.FC<React.PropsWithChildren<Props>> = ({
  cakePriceUsd,
  color = "textSubtle",
  showSkeleton = true,
  chainId,
}) => {
  return cakePriceUsd ? (
    <PriceLink
      href="https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/?outputCurrency=0x97f7259931f98CC64EbCd993fdE03d71716f3E07"
      target="_blank"
    >
      {/*
      <LogoRound width="24px"  />
       */}
      <Flex justifyContent="center">
        <img src="/images/logos/bw_black.png" alt="nuts" width="36px" />
      </Flex>
      <Text color={color} bold>{`$${cakePriceUsd.toFixed(2)}`}</Text>
    </PriceLink>
  ) : showSkeleton ? (
    <Skeleton width={80} height={24} />
  ) : null;
};

export default React.memo(CakePrice);
