import { HelpIcon } from '@pancakeswap/uikit'
import { ASSET_CDN } from 'config/constants/endpoints'
import Image from 'next/image'
import { memo } from 'react'
import { isChainSupported } from 'utils/wagmi'

export const ChainLogo = memo(
  ({
    chainId,
    width = 24,
    height = 24,
    greyscale = false,
  }: {
    chainId?: number
    width?: number
    height?: number
    greyscale?: boolean
  }) => {
    if (chainId && isChainSupported(chainId) && chainId === 369 && greyscale) {
      return (
        <Image
          alt={`chain-${chainId}`}
          style={{ maxHeight: `${height}px`, filter: 'grayscale(100%)' }}
          src="/images/pulsechain.png"
          width={width}
          height={height}
        />
      )
    }
    if (chainId && isChainSupported(chainId) && chainId === 369) {
      return (
        <Image
          alt={`chain-${chainId}`}
          style={{ maxHeight: `${height}px` }}
          src="/images/pulsechain.png"
          width={width}
          height={height}
        />
      )
    }
    if (chainId && isChainSupported(chainId)) {
      return (
        <Image
          alt={`chain-${chainId}`}
          style={{ maxHeight: `${height}px` }}
          src={`${ASSET_CDN}/web/chains/${chainId}.png`}
          width={width}
          height={height}
        />
      )
    }

    return <HelpIcon width={width} height={height} />
  },
)
