const variants = {
  warning: {
    background: "#FFB23719",
    bordercolor: "warning",
  },
  danger: {
    background: "#2A809519",
    bordercolor: "failure",
  },
  success: {
    background: "rgba(49, 208, 170, 0.1)",
    bordercolor: "success",
  },
  primary: {
    background: "rgba(118, 69, 217, 0.1)",
    bordercolor: "secondary",
  },
};

export default variants;
