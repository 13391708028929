import { ChainId } from '@pancakeswap/chains'
import { chainlinkOracleCAKE } from '@pancakeswap/prediction'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import BigNumber from 'bignumber.js'
import { chainlinkOracleABI } from 'config/abi/chainlinkOracle'
import { publicClient } from 'utils/wagmi'
import { Address, formatUnits } from 'viem'
import useTokenPriceUSD from './useTokenPrices'
import usePlsPriceUSD from './usePlsPrices'

// for migration to bignumber.js to avoid breaking changes
export const useCakePrice = ({ enabled = true } = {}) => {
  /*
  const { datad2 } = useQuery<BigNumber, Error>({
    queryKey: ['cakePrice'],
    queryFn: async () => new BigNumber(await getCakePriceFromOracle()),
    staleTime: FAST_INTERVAL,
    refetchInterval: FAST_INTERVAL,
    enabled,
  })
    */
  const divider = new BigNumber('10').pow(18)

  const data = useCakePriceFromRouter()

  const decimalNumber = data ? data.dividedBy(divider) : BIG_ZERO

  return decimalNumber ?? BIG_ZERO
}

export const usePlsPrice = ({ enabled = true } = {}) => {
  /*
  const { datad2 } = useQuery<BigNumber, Error>({
    queryKey: ['cakePrice'],
    queryFn: async () => new BigNumber(await getCakePriceFromOracle()),
    staleTime: FAST_INTERVAL,
    refetchInterval: FAST_INTERVAL,
    enabled,
  })
    */
  const divider = new BigNumber('10').pow(18)

  const data = usePlsPriceFromRouter()

  const decimalNumber = data ? data.dividedBy(divider) : BIG_ZERO

  return decimalNumber ?? BIG_ZERO
}

export const useCakePriceFromRouter = ({ enabled = true } = {}) => {
  const { price } = useTokenPriceUSD(
    '0x97f7259931f98CC64EbCd993fdE03d71716f3E07' as Address,
    '0x165C3410fC91EF562C50559f7d2289fEbed552d9' as Address,
  )
  return price ?? BIG_ZERO
}

export const usePlsPriceFromRouter = ({ enabled = true } = {}) => {
  const { price } = usePlsPriceUSD('0x165C3410fC91EF562C50559f7d2289fEbed552d9' as Address)
  return price ?? BIG_ZERO
}

export const getCakePriceFromOracle = async () => {
  const data = await publicClient({ chainId: ChainId.BSC }).readContract({
    abi: chainlinkOracleABI,
    address: chainlinkOracleCAKE[ChainId.BSC],
    functionName: 'latestAnswer',
  })

  return formatUnits(data, 8)
}
